import React from "react"
import { Link, graphql } from "gatsby"
import { Button } from '@material-ui/core'
import Img from 'gatsby-image'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import Swiper from 'react-id-swiper';
import { MDXProvider } from '@mdx-js/react'
import Layout from "../components/layout"
import ShareBoxStory from "../components/shareBoxStory"
import SEO from "../components/seo"
import { connect } from 'react-redux';
import { updateDesign, updateStage } from '../actions';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ currentDesign, currentLang }) => {
  return { currentDesign, currentLang }
}

const mapDispatchToProps = dispatch => ({
  updateDesign: (designValue) => dispatch(updateDesign(designValue)),
  updateStage: (stageValue) => dispatch(updateStage(stageValue))
})

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const t = this.props.t
    const post = this.props.data.mdx
    const { previous, next, nextTwo } = this.props.pageContext
    const featuredImg = post.frontmatter.featuredImage
    const innerImg = post.frontmatter.innerImage
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      renderPrevButton: () => <button className="swiper-button-prev"><i className="icon icon-prev"/></button>,
      renderNextButton: () => <button className="swiper-button-next"><i className="icon icon-next"/></button>,
      spaceBetween: 100,
      loop: true,
      lazy: true,
    }

    const Slideshow = () => {
      return(
        <Swiper {...params}>
          { innerImg.map((image, index) => (
            <Img key={index} fluid={image.childImageSharp.fluid} style={{ width: `100%`}} />
          ))}
        </Swiper>
      )
    }

    const shortcodes = { Slideshow }

    return (
      <MDXProvider components={shortcodes}>
        <SEO
          title={post.frontmatter.title}
        />
        <article className={"blog " + this.props.currentLang}>
          <header>
            <Img className="blog__cover" style={{maxWidth: `1200px`, margin: `0 auto`}} fluid={featuredImg.childImageSharp.fluid} />
            <h2
              style={{
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h2>
            <ShareBoxStory currentSocial={post.frontmatter.social}/>
            <hr/>
          </header>
          <MDXRenderer>{post.body}</MDXRenderer>
          <Link
            className="blog__link basicButton"
            to={"/" + post.frontmatter.product}
            onClick={() => 
              {
                const item = {
                  product: "",
                  case: []
                }
                item.case = post.frontmatter.case
                item.product = post.frontmatter.product
                this.props.updateDesign(item)
                this.props.updateStage('comp-pick')
              }
            }>
              { t('story.useTemplate') }
          </Link>
          <hr/>
          <nav>
            <div className="blog__explore">{ t('story.exploreMore') }</div>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
                margin: `0 -10px`
              }}
            >
              <li style={{ backgroundImage: `url(` + 'src' + `)`}}>
                <Img alt={previous.frontmatter.title} fluid={previous.frontmatter.thumbImage.childImageSharp.fluid} />
                {previous && (
                  <Link to={previous.fields.slug} rel="prev" />
                )}
              </li>
              <li>
                <Img alt={next.frontmatter.title} fluid={next.frontmatter.thumbImage.childImageSharp.fluid} />
                {next && (
                  <Link to={next.fields.slug} rel="prev" />
                )}
              </li>
              <li>
                <Img alt={nextTwo.frontmatter.title} fluid={nextTwo.frontmatter.thumbImage.childImageSharp.fluid} />
                {nextTwo && (
                  <Link to={nextTwo.fields.slug} rel="prev" />
                )}
              </li>
            </ul>
          </nav>
        </article>
      </MDXProvider>
    )
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BlogPostTemplate));


export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        innerImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thumbImage {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        case {
          comp
          color
        }
        social {
          name
          link
          icon
        }
        product
      }
    }
  }
`
